.main {
    position: fixed;
    /* background: transparent; */
    background: linear-gradient(72deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 9.67%, rgb(246, 246, 246) 32.14%, rgb(236, 238, 240) 100%) 0% 0% / cover, linear-gradient(72deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 9.67%, rgb(246, 246, 246) 32.14%, rgb(236, 238, 240) 100%);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
}

.main_page {
  flex-grow: 1;
  margin: 0 1.5rem;
  margin-right: 0px;
  width: calc(100vh - 277px);
}

.page {
    flex-grow: 1;
    margin: 0px 1.5rem;
    overflow-y: scroll;
    height: calc(100vh - 104px);
    background-color: white;
    border-radius: 20px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
}

.sidebar {
  width: 14.625rem
}

.page::-webkit-scrollbar {
    display: none; /* WebKit-based browsers */
  }

.outletContent{
    border-radius: 20px;
    background-color: #FFFFFF;
    height: calc(100vh - 87px);
    overflow: auto;
}
::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #c2c9d2;
  }
.content {
  display: flex;
  gap: 1.5rem;
  margin: 0 1.2rem;
  margin-right: 0.4rem;
  padding-top: 0.7rem;
}

.no_id {
  display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40rem;
    flex-direction: column;
    gap: 3rem;
}

.no_id img {
  width: 30rem;
}

.no_id span {
  font-weight: 700;
}

@media screen and (max-width: 950px) {
    .sidebar {
      display: none;
    }
    .main {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .main_page {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .content {
      flex-direction: column;
      overflow-y: scroll;
      margin: 0;
      padding: 0;
      padding-top: 1rem;
    }

    .outletContent{
      border-radius: 20px;
      background-color: #FFFFFF;
      margin: 0;
      padding: 0;
  }
  
}