.custom_table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.custom_table_head {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    text-align: left;
    color: #101828;
    background-color: #FAFAFA;
}

.custom_table_body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.82px;
    background-color: white;
    cursor: pointer;
}

.custom_table_row:hover {
    background-color: rgb(236, 234, 234);
    border-radius: 8px;
}

.custom_table_row {
    width: 100%;
}

.custom_table_cell {
    padding: 0.8rem;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.82px;
    text-align: left;
    width: auto; 
    width: 12.5rem;
    height: 1.875rem;
    gap: 0px;
    opacity: 0.9;
}

.AddNewCycle {
    min-width: 14.25rem;
    height: 3.4rem;
    padding: 0.75rem 2.5rem;
    gap: 0.625rem;
    border-radius: 0.625rem;
    opacity: 0.9;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
    text-align: left;
    text-decoration-skip-ink: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.AddNewCycleText {
    height: 1.375rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    text-align: left;
    text-decoration-skip-ink: none;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


@media (max-width: 600px) {
    .AddNewCycle {
        padding: 0.75rem 1.5rem; 
        min-width: 10rem; 
    }
    .AddNewCycleText {
        font-size: 0.875rem; 
    }
}


.AddNewCycleTextPopup {
    width: 9.25rem;
    height: 1.375rem;
    gap: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
    text-align: left;
    text-decoration-skip-ink: none;
    color: black;
}

.CycleList {
    width: 45.6875rem;
    height: 1.375rem;
    gap: 0px;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: left;
    text-decoration-skip-ink: none;
    color: black;
}

.cycle_code_cell {
    color: rgba(90, 138, 234, 1);
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 700;
    line-height: 21.82px;
    text-align: left;
    text-decoration-skip-ink: none;

}

.tb_main_text {
    font-weight: 500;
}

.squareBox {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.25rem;
    margin-right: 0.625rem;
}

.greenBox {
    background-color: green;
}

.secondBox {
    background-color: rgba(255, 225, 200, 1);
}

.labelText {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    text-align: center;
    text-decoration-skip-ink: none;
    color: rgba(98, 90, 90, 1);

}


.secondBox {
    background-color: rgba(255, 225, 200, 1);
}

/* popup css */

.container {
    padding-left: 16px;
    padding-right: 16px;

    button {
        height: 2.875rem;
        width: 14.875rem;
        margin-right: 10px;
        border: none;
        border-radius: 10px;
    }

    .textareaWrapper {
        textarea::placeholder {
            color: rgba(187, 187, 187, 1);
        }
    }
}



@media (max-width: 768px) {
    .custom_table {
      font-size: 14px; 
    }
  
    .custom_table_cell {
      padding: 0.5rem; 
    }
  
    .custom_table_row {
      font-size: 12px;
    }
  }
  


/* isTerminateCycleDialogOpen */

.container {
    padding-left: 16px;
    padding-right: 16px;

    button {
        height: 2.875rem;
        width: 14.875rem;
        margin-right: 10px;
        border: none;
        border-radius: 10px;
    }

    .textareaWrapper {
        textarea::placeholder {
            color: rgba(187, 187, 187, 1);
        }
    }
}

.textspan {
    display: grid;
}

.titleText {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: left;
    color: rgba(35, 35, 35, 1);
}

/* Add this to your CSS file */
.searchField {
    width: 285px;  /* Width of the text field */
    height: 23px;  /* Height of the text field */
    margin-right: 1rem; /* Space between the search field and the button */
  }
  
.spantext {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    color: #404653;
    text-align: left;
}

.floatEnd {
    cursor: pointer;
}

.cancelButton {
    width: 7.5rem;
    height: 2.875rem;
    border: 1px solid #505050;
    background-color: white;
    color: #505050;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    border-radius: 10px;
    text-transform: none;
}

.cancelButton:hover {
    background-color: #f2f2f2;
}


.customButton {
    width: 7.5rem;
    /* 120px / 16 = 7.5rem */
    height: 2.875rem;
    /* 46px / 16 = 2.875rem */
    gap: 0.625rem;
    /* 10px / 16 = 0.625rem */
    border-radius: 0.625rem;
    /* 10px / 16 = 0.625rem */
    background-color: #505050;
    color: white;
    opacity: 1;
    transition: opacity 0.3s ease;
    text-transform: none;
}

/* CycleDetails */

.stepstext {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 700;
    line-height: 21.82px;
    text-decoration-skip-ink: none;
    color: rgba(35, 35, 35, 1);
    cursor: pointer;

}

.activeStepText {
    color: rgba(225, 126, 97, 1);
    cursor: pointer;
}

.CycleDetails {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(35, 35, 35, 1);
}

.headerCycleDetails {
    font-family: 'Nunito';
    font-size: 1rem; /* 16px / 16 = 1rem */
    font-weight: 400;
    line-height: 1.364rem; /* 21.82px / 16 ≈ 1.364rem */
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(187, 187, 187, 1);
}
.cycleList {
    font-family: 'Nunito';
    font-size: 1rem; /* 16px / 16 = 1rem */
    font-weight: 400;
    line-height: 1.364rem; /* 21.82px / 16 ≈ 1.364rem */
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    color: rgba(90, 138, 234, 1);
    cursor: pointer;
}
.CycleDetailsStep{
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem; /* 16px to rem */
    font-weight: 700;
    line-height: 1.21875rem; /* 19.5px to rem */
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(35, 35, 35, 1);
    
}
.CycleDetailsStepView{
    font-family: 'Nunito', sans-serif;
    font-size: 1rem; /* 16px to rem */
    font-weight: 400;
    line-height: 1.36375rem; /* 21.82px to rem */
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(90, 138, 234, 1);
    cursor: pointer;
    margin-left: 10px;
}

.boxstepper{
    padding: 25px;
    border: 1.12px solid rgba(226, 227, 229, 1);
    box-sizing: border-box;
    border-radius: 13.43px;
}


.boxWrapper {
    display: flex; 
    align-items: center; 
    gap: 10px;   
}

.box {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    opacity: 1;
    display: inline-block;
    margin-right: 5px;
}

.box1 {
    background: rgba(71, 179, 95, 1); 
}

.box2 {
    background: rgba(255, 225, 200, 1); 
}

.customtext {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    text-align: center;
    text-decoration-skip-ink: none;
    color: rgba(98, 90, 90, 1); 
}

/* message for no data found */
.noDataFound {
    text-align: left;
    font-weight: bold;
    color: #101828;
    padding: 1rem;
  }
  

  .statusBadgePending {
    display: inline-block;
    padding: 6px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 30px;
    color: #d4af37;
    background-color: #fff8e1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.statusBadgeSuccess {
    display: inline-block;
    padding: 6px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 30px;
    color: #ffffff;
    background-color: #4caf50;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, background-color 0.3s;
}


  

  