  input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 1);
    opacity: 1;
    display: block;
    background: url(../../../assets/SVG/calendar.svg) no-repeat;
    width: 18px;
    height: 18px;
    border-width: thin;
  } 

  input[type="month"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 1);
    opacity: 1;
    display: block;
    background: url(../../../assets/SVG/calendar.svg) no-repeat;
    width: 18px;
    height: 18px;
    border-width: thin;
  } 
  
  input[type="time"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 1);
    opacity: 1;
    display: block;
    background: url(../../../assets/SVG//clock_icon.svg) no-repeat;
    width: 18px;
    height: 18px;
    border-width: thin;
  }
   
  .resetButton {
    width: 7.5rem;              
    height: 2.875rem; 
    border: 1px solid #505050; 
    background-color: white;   
    color: #505050;            
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;           
    text-decoration: none; 
    cursor: pointer;   
    border-radius: 10px;
    text-transform: none;
  }
  
  .resetButton:hover {
    background-color: #f2f2f2;  
  }
    
  .cancelButton {
    width: 7.5rem;              
    height: 2.875rem; 
    border: 1px solid #505050; 
    background-color: white;   
    color: #505050;            
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;           
    text-decoration: none; 
    cursor: pointer;   
    border-radius: 10px;
    text-transform: none;
  }

  .cancelButton:hover {
    background-color: #f2f2f2;  
  }

  .customButtonDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 1rem;  
  }
  
  .customButton {
    width: 7.5rem;
    height: 2.875rem;
    gap: 0.625rem;
    border-radius: 0.625rem;
    background-color: #505050;
    color: white;
    opacity: 1;
    transition: opacity 0.3s ease;
    text-transform: none;
  }
  
  .customButton.disabled {
    background-color: #505050;
    color: #dad4d4; 
    cursor: not-allowed;
    opacity: 0.6; 
    text-transform: none;
  }
    
  .customaddDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  
  .terminateButton {
    width: 10.5rem;              
    height: 2.875rem; 
    border: 1px solid #505050; 
    background-color: white;   
    color: #505050;            
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;           
    text-decoration: none; 
    cursor: pointer;   
    border-radius: 10px;
    text-transform: none;
  }
  
  .terminateButton:hover {
    background-color: #f2f2f2;  
  }

  /* Add this to your styles (oocyteRetrival.module.css or a relevant CSS file) */
.imagePreview {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.imagePreview:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}
