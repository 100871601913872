.container {
    padding: 0 1rem; /* 16px to rem */
  }
  
  .title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem; /* 18px to rem */
    font-weight: 700;
    line-height: 1.374rem; /* 21.94px to rem */
    text-align: left;
    color: #666666;
  }
  
  .button {
    width: 14.6875rem; /* 235px to rem */
    height: 2.875rem; /* 46px to rem */
    gap: 0.625rem; /* 10px to rem */
    border-radius: 0.625rem; /* 10px to rem */
    opacity: 1; 
    font-family: 'Montserrat', sans-serif; 
    font-size: 1rem; /* 16px to rem */
    font-weight: 700; /* Font weight */
    line-height: 1.375rem; /* 22px to rem */
    text-align: left; /* Text alignment */
    color: #FFFFFF; /* Text color */
  }

