.bar {
    display: flex;
    width: --webkit-fill-available;
    padding: 0.7rem;
    flex-direction: row;
    align-items: center;
    gap: 1.2rem;
    border-bottom: 1px solid #E17E61;
    padding-bottom: 0;
}

.logo_container img {
    width: 8.375rem;
    height: 2.875rem;
    flex-shrink: 0;
}

.logo_container {
    display: flex;
    width: 15.625rem;
    justify-content: space-between;
    align-items: center;
    
}

.bd_container {
    border-radius: 1rem;
    /* background: linear-gradient(63deg, #FFF 17.35%, rgba(252, 252, 252, 0.55) 58.44%, rgba(255, 255, 255, 0.00) 83.98%); */
    flex: 1;
    height: 3.5rem;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
}

.icn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: #FFF;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
}

.icn_container:hover {
    box-shadow: 1px 4px 20px #dddddd;
}

.icons {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0.5rem;
}

.end {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
}

.profile {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.drawer {
    display: none;
}

@media screen and (max-width: 950px) {
    .drawer {
        display: unset;
    }

    .logo_container {
        display: none;
    }
}