.container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .headerText {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.37125rem;
    text-align: left;
    color: #232323;
  }
  
  
  .accordionContainer {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .accordionContainer {
    width: 100%;
    display: block;
    padding: 1rem 1.25rem;
    box-sizing: border-box;
  }
  
  .accordion {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #80808059;
    border-radius: 7px;
    --Paper-shadow: none !important;
  }
  
  .header {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    line-height: 26.1.5px;
    text-align: left;
    color: #232323;
  
  }
  
  .customButtonDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 24px;
  }
  
  .customButton {
    width: 7.5rem;
    height: 2.875rem;
    border-radius: 0.625rem;
    background-color: #505050;
    color: white;
    opacity: 1;
    transition: opacity 0.3s ease;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
  }
  
  .customButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Reset button specific styles */
  .resetButton {
    width: 7.5rem;
    height: 2.875rem;
    border: 1px solid #505050;
    background-color: white;
    color: #505050;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
    text-transform: none;
  }
  
  .resetButton:hover {
    background-color: #f2f2f2;
  }
  
  .headerClosed {
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
  
  .headerOpen {
    box-shadow:
      0px 4px 12px 0px rgba(255, 245, 241, 1);
    border-radius: 6px;
  }
  
  .accordionSummaryOpen {
    background-color: #fffaf8;
    border-radius: 6px;
  }
  
  .accordionSummaryOpen .MuiTypography-root {
    color: #333;
  }

  .label {
    display: flex;
    flex-direction: column;
  }

  .label label {
    color: #6b6969;
    font-size: 0.8rem;
    font-family: 'Nunito';
  }

  .label span {
    color: #252424;
    font-size: 1rem;
    font-family: 'Nunito';
  }
  
  h4.tableHeader {
    background: #FAFAFA;
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }
  
  .tableEnteries h6 {
    background: #FAFAFA;
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    padding: 0 12px;
    margin: 4px 0;
  }

  .input {
    overflow: scroll;
    height: 21rem;
    width: 100%;
  }
  
  .inputRow {
    padding: 12px 0;
    border-bottom: 1px solid #E2E3E5;
  }
  
  .folliSizeInput {
    padding: 0 12px;
  }

  .frows {
    width: 100%;
    height: 20rem;
    overflow: auto;
  }
  
  .folliSizeInput input {
    width: 120px;
  }
  
  .folliCountInput {
  
    padding: 0 12px;
  }
  
  .folliCountInput input {
    width: 50px;
    color: #232323;
  }
  
  .addMoreRows {
    margin-top: 10px;
    text-align: right;
  
  }
  
  .addMoreRows button {
    font-size: 14px;
    font-weight: 700;
    line-height: 20.3px;
    text-decoration-line: underline;
    color: #5A8AEA;
  
  }
  
  .saveBtnsDiv {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-bottom: 1rem;
  }
  
  button.saveFinalButton,
  button.cancelButton,
  button.customButton {
    height: 46px;
    border-radius: 10px;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    text-transform: none;
    font-size: 16px;
    font-weight: 700;
    box-shadow: none;
  }
  
  button.saveFinalButton {
    border: 1px solid #F3F3F3;
    background: #F3F3F3;
    color: #505050;
  }
  
  .saveFinalButton:hover {
    background-color: #f2f2f2;
  }
  
  
  button.cancelButton {
    border: 1px solid #505050;
    background-color: #fff;
    color: #505050;
  }
  
  .cancelButton:hover {
    background-color: #f2f2f2;
  }
  
  .customButton {
    background-color: #F3F3F3;
    color: black;
  
  }
  .successMessage {
    margin: 10px 0;
    padding: 10px;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
  }
  