.container {
    padding: 50px;
    border: 1.12px solid rgba(226, 227, 229, 1);
    box-sizing: border-box;
    border-radius: 13.43px;
    justify-content: center;
    align-items: center;
}

.boxContainer {
    border: 1px solid #ccc;
    /* Add border around the box */
    border-radius: 8px;
    /* Optional: rounded corners */
    padding: 16px;
    /* Add some space inside the box */
    background-color: #f9f9f9;
    /* Light background color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: subtle shadow */
    margin: 10px;
    /* Add margin between the boxes */
}

.containerprofile {
    display: flex;
}

.semenProcessContainer {
    margin-right: 20px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.headerTextStyle {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(187, 187, 187, 1);
}

.radioLabel {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.3px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #232323; /* Corrected the color hex */
  }
  

.customTextStyle {
    font-family: 'Nunito', sans-serif;
    font-size: 12.43px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(35, 35, 35, 1);
}

.name {
    font-family: 'Nunito';
    font-size: 0.64rem;
    font-weight: 600;
    line-height: 0.90rem;
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(35, 35, 35, 1);
}

.hello {
    font-family: 'Nunito';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.05rem;
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(187, 187, 187, 1);

}

.funnyText {
    font-family: 'Nunito';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.05rem;
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(205, 19, 19, 1);
}

.funnyTextrefrence {
    font-family: 'Nunito';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.05rem;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #232323;
}

.headerTextStyleimg {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #232323;
}