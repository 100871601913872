.name {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--Text-Primary, #232323);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Nunito;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem; /* 142.857% */
}

.name img {
    height: 1rem;
    width: 1rem;
}

.item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    grid-area: 0.4rem;
}

.item svg {
    margin-right: 0.3rem;
}