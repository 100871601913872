.selectedTab {
    width: 185.25px;
    height: 36px;
    padding: 8px 16px;
    gap: 10px;
    border-radius: 10px 0px 0px 0px;
    opacity: 1; /* Ensure opacity is 1 for the selected tab */
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1); /* Soft shadow for selected tab */
    background-color: #FFFFFF; /* White background for the selected tab */
    border: none; /* Remove any border */
  }
  
  .selectedTab .MuiTab-wrapper {
    color: #E17E61; /* Text color for the selected tab */
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }


  .tableHead {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;

  }
  

  .resetButton {
    width: 7.5rem;              
    height: 2.875rem; 
    border: 1px solid #505050; 
    background-color: white;   
    color: #505050;            
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;           
    text-decoration: none; 
    cursor: pointer;   
    border-radius: 10px;
    text-transform: none;
  }
  
  .resetButton:hover {
    background-color: #f2f2f2;  
  }
    
  .cancelButton {
    width: 7.5rem;              
    height: 2.875rem; 
    border: 1px solid #505050; 
    background-color: white;   
    color: #505050;            
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;           
    text-decoration: none; 
    cursor: pointer;   
    border-radius: 10px;
    text-transform: none;
  }
  
  .cancelButton:hover {
    background-color: #f2f2f2;  
  }
  
  .customButtonDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 1rem;  
  }
  
  .customButton {
    width: 7.5rem;
    height: 2.875rem;
    gap: 0.625rem;
    border-radius: 0.625rem;
    background-color: #505050;
    color: white;
    opacity: 1;
    transition: opacity 0.3s ease;
    text-transform: none;
  }
  
  .customButton.disabled {
    background-color: #505050;
    color: #dad4d4; 
    cursor: not-allowed;
    opacity: 0.6; 
    text-transform: none;
  }