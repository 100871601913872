.navigation {
  width: 14.625rem;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  height: 60px;
}

.navigation ul {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 0;
  /* margin-top: 28px; */
  top: 16px;
}

.navigation ul li {
  position: relative;
  display: flex;
  justify-content: center;
  list-style: none;
  height: 60px; 
  z-index: 111;
  margin-top: 2px;
  cursor: pointer;
}
.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  font-weight: 200;
  cursor: pointer;
  height: 100%; 
  
  margin-top: 10px;
}
.navigation ul li a .icon {
  display: block;
  line-height: 1;
  font-size: 2em; 
  transition: 0.7s;
  color: black;
  /* margin-top: 5px; */
}

.indicator {
  position: absolute;
  left: 0;
  top: -3px;
  /* transition: 0.7s; */
  transition: transform 0.7s ease, opacity 0.7s ease; 
}

.indicator::before {
  content: "";
  position: absolute;
  top: -10%;
  left: 60px;
  width: 60px;
  height: 42px;
  background: #f7f7f7;;
  border-bottom-left-radius: 20px;
  box-shadow: -25px 20px 20px -7px rgb(241, 241, 241);
}

.imgmain {
  position: absolute;
  bottom: 8%;
  width: 50%;
  left: 50px;
}

  .adminmenu li {
    position: relative;
    width: 214px;
    height: 42px;
  }
  
  .adminmenu li::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    left: -18px;
    top: 6px;
    z-index: 10;
  }
  
  .adminmenu li.active::before {
    background-color: #e17e61;
    border: 5px solid rgb(216, 219, 228);
  }
  
  .adminmenu li.inactive::before {
    background-color: transparent;
  }
  
  .adminmenu li.inactive-above-active::before {
    display: none;
  }
  
  .adminmenu li.inactiveGrey::before {
    background-color: #cfd1d4;
    border: 5px solid rgb(255, 255, 255);
  }
  
  .adminmenu li.activeChild::before {
    border: 5px solid rgb(255, 255, 255);
  }
  
  .headeradmin {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: inline-flex;
    padding: 12px 24px;
    align-items: center;
    gap: 12px;
  }
  
  .anchoradmin {
    margin-left: -20px;
    color: #E17E61;
    font-family:  'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    cursor: pointer
  }
  
  .navigationsidebar {
    width: 14.625rem;
    margin-left: .5rem;
    position: absolute;
    left: 9px;
    position: fixed;
    background-color: #FFFFFF;
    background: url(../../assets/SVG/Watermark.svg);
    border-radius: 40px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-x: 87%;
    background-size: 170%;
    background-position-y: 98%;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.06);
    height: calc(100% - 150px);
    margin-top: .5rem !important;
  }
  
  .imgadminicon {
    margin: 20px;
    width: 24px;
    height: 24px;
  }
  
  .sidebarunorderedList {
    border-bottom-right-radius: 20px; 
    border-top-right-radius: 20px; 
    border-top-left-radius: 20px; 
  }
  
  .orderlistDiv {
    background-color: #ffff;
    border-radius: 20px 20px 20px 0;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }
  
  .orderlistLi {
    cursor: pointer;
    color: #A4A4A4;
    font-family:  'Montserrat', sans-serif;
    font-size: 16px;
    padding-left: 35px;
    list-style-type: none;
    padding-top: 15px;
    padding-bottom: 11px;
  }
  
  .boldClass {
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-family:  'Montserrat', sans-serif;
    font-size: 15px;
    padding-left: 35px;
    list-style-type: none;
    padding-top: 15px;
    padding-bottom: 11px;
    cursor: pointer;
  }
  .boldClass1 {
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-family:  'Montserrat', sans-serif;
    font-size: 15px;
    padding-left: 35px;
    list-style-type: none;
    padding-top: 15px;
    padding-bottom: 11px;
    cursor: pointer;
  }
  
  .subunorderList {
    background-color: transparent;
    padding-inline: 40px;
    background: linear-gradient(180deg, #EEEEEE 20.06%, rgba(243, 243, 243, 0.547773) 75.62%, rgba(232, 234, 236, 0) 190.17%);
    border-radius: 20px 0 0 0;
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important;
    padding-left: 3rem;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-right: 1.5rem !important;
  }
  
  .subunorderedlistLi {
    font-family:  'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    padding-left: 13px;
    cursor: pointer;
    list-style-type: none;
    padding-top: 5px;
    padding-bottom: 11px;
    font-weight: 500;
    line-height: 17.07px;
    color: rgba(35, 35, 35, 1);
    opacity: 0;
    animation-name: fadeInli;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  .subunorderedlistLiChecked {
    font-family:  'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.07px;
    color: rgba(225, 126, 97, 1);
    padding-left: 13px;
    cursor: pointer;
    list-style-type: none;
    padding-top: 5px;
    padding-bottom: 11px;
  }

  .versiontext{
    position: absolute;
    bottom: 5%;
    width: 100%;
    left: 64px;
    font-family:  'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color:#CFD1D4;
  }


  .subunorderList li::before {
    border: 5px solid white;
    background: rgba(207, 209, 212, 1);
  }

  .subunorderList li.active::before {
    border: 5px solid white;
    background: rgba(225, 126, 97, 1);
  }
  /* background: rgba(225, 126, 97, 1); */

  .svgclass{
    background-color: white;
    border-radius: 20px;
    padding: 5px 5px;
    margin-left: 0px;
    height: 32px;
    cursor: pointer;
    width: 32px;
  }
  
  .subunorderList {
    position: relative;
    animation-name: fadeIn;
    animation-duration: 0.7s; /* adjust the duration as needed */
    animation-iteration-count: 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .subunorderList.fadeOut {
    animation-name: fadeOut;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeInli {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0.25;
    }
    50%{
      opacity: 0.5;
    }
    75%{
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }

  .userCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding-left: 0.5rem;
    cursor: pointer;
  }

  .userCard label {
    font-family: nunito;
    font-weight: bold;
    color: black;
  }

  .userCard img {
    height: 2.1rem;
    width: 2.1rem;
    border-radius: 50%;
  }

  .mainclass label {
    cursor: pointer;
  }

  .selectedPatient, .selectedPatientDetails{
    gap: 0.7rem;
    background-color: #fff;
  }

  .selectedPatient:hover{
    cursor: pointer;
  }
  .patientName{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .patientName p{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 21.94px;
    text-align: left;
    color:#232323;
    margin-bottom: 0px !important;
    
}

  @media screen and (max-width: 950px) {
    .customDrawer .MuiDrawer-paper {
      width: 250px;
    }
  }
  