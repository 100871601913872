.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    background-color: #fff;
    box-shadow: 24px;
    border-radius: 15px;
}

.modalSmall {
    width: 450px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #E2E3E5;

}

.modal h3 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

button.closeBtn {
    background: #D7D7D7;
    height: 26px;
    color: #fff;
    width: 26px;
    font-size: 10px;
}

button.closeBtn svg {
    font-size: 16px;
}

.modal .modalBody {
    padding: 16px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
}

.deleteNote {
    display: flex;
    align-items: center;
    justify-items: center;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.info label {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9E9E9E;
}

.info span {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.82px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #232323;
}

.deleteNoteImg {
    display: flex;
    height: 45px;
    width: 45px;
    align-items: center;
    justify-items: center;
    background: #FDDFD7;
    padding: 8px;
    border-radius: 100%;
    border: 4px solid #FFF0EC;
}

.deleteNoteImg img {
    height: 25px;
    width: 22px;
}

.deleteNote h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
}

.deleteNote p {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
}

.modalActions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
}

.modalActions button {
    width: 100%;
    height: 46px;
    padding: 12px 24px;
    gap: 10px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    transition: ease-in 0.5s all;
}

.modalActions button.modalCancelBtn {
    background: #F3F3F3;
    border: 1px solid #F3F3F3;
}

.modalActions button:hover {
    opacity: 0.7;
}

.modalActions button.buttonSmall {
    width: auto;
    background: #505050;
    padding: 0 40px;
}


@media (max-width: 768px) {
    .modal {
        width: 100%;
    }

    .row {
        flex-direction: column;
        gap: 16px;
    }
}

.primary {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    padding: 0.2rem;
}