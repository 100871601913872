.container {
  padding: 50px;
  border: 1.12px solid rgba(226, 227, 229, 1);
  box-sizing: border-box;
  border-radius: 13.43px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}

@media (max-width: 600px) {
  .container {
    padding: 20px;
  }
}

.schedulecontainer {
  display: block; 
  font-family: Arial, sans-serif;
  overflow-x: auto; 
}

.tableContainer {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 10px;
  overflow-x: auto;
  background-color: rgba(250, 250, 250, 1);
  border-radius: 8px;
  overflow-x: auto;
}

.tableHeader,
.tableCell {
  padding: 12px;
  text-align: center;
}

.tableHeader {
  font-weight: bold;
}
.tableCell {
  font-family: 'Montserrat';
  font-size: 13.43px;
  font-weight: 800;
  line-height: 18.8px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.tableCellsub {
  font-family: 'Montserrat';
  font-size: 13.43px;
  font-weight: 600;
  line-height: 18.8px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.dayNumber,
.dayName {
  display: block;
}

.eventCell {
  background-color: rgba(216, 102, 80, 1);
  color: white;
  border-radius: 12px;
  font-weight: bold;
  text-align: center;
}

tr.mt-4 {
  margin-top: 4rem;
}

tbody tr:not(:last-child) {
  margin-bottom: 2rem;
}

.eventCell {
  border-radius: 27px;
  background: rgba(216, 102, 80, 1);
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
}

.textheader {
  font-family: 'Montserrat';
  font-size: 13.43px;
  font-weight: 600;
  line-height: 18.8px;
  text-align: left;
  text-decoration-skip-ink: none;
}
.doseCell {
  text-align: center;
  font-weight: bold;
  padding: 10px; 
  background-color: rgba(253, 236, 232, 1);
  border-radius: 27px;
  margin: 0; 
  border-spacing: 0; 
}


.doseRow {
  margin-top: 4rem;
}

.dose250 {
  background-color: rgba(253, 236, 232, 1);
  height: 2px;
}

tbody tr:not(:last-child) {
  margin-bottom: 2rem;
}

tr.mt-4 {
  margin-top: 4rem;
}

@media (max-width: 1200px) {
  .tableHeader, .tableCell, .eventCell, .doseCell {
    font-size: 12px; 
  }

  .schedulecontainer {
    display: block;
  }
}

@media (max-width: 900px) {
  .tableContainer {
    font-size: 10px;
  }

  .tableHeader, .tableCell, .eventCell, .doseCell {
    padding: 8px; 
  }

  .schedulecontainer {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 600px) {
  .schedulecontainer {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .tableContainer {
    font-size: 8px; 
  }

  .tableHeader, .tableCell, .eventCell, .doseCell {
    padding: 6px; 
  }

  .tableCell {
    display: block;
    width: 100%;
    text-align: left;
    padding: 6px 10px;
  }
}

/* Keyframe for fading in */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.tableContainer td, .tableContainer th {
  opacity: 0; 
  animation: fadeIn 0.5s forwards;
}


.schedulecontainer .tableContainer tr td,
.schedulecontainer .tableContainer tr th {
  animation-delay: calc(0.2s * var(--delay)); 
}


.schedulecontainer .tableContainer tr:nth-child(1) td {
  --delay: 1; 
}

.schedulecontainer .tableContainer tr:nth-child(2) td {
  --delay: 2; 
}

.schedulecontainer .tableContainer tr:nth-child(3) td {
  --delay: 3; 
}

.schedulecontainer .tableContainer tr:nth-child(4) td {
  --delay: 4; 
}


.schedulecontainer .tableContainer tr td {
  --delay: calc(0.5 * var(--row-index));
}

@media (max-width: 600px) {
  .tableContainer td, .tableContainer th {
    animation-duration: 0.7s; 
  }
}
