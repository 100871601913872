.filterPopupContent {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
}

.filterRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.filterName,
.filterNameText {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: left;
    color: rgba(35, 35, 35, 1);
}

.closeBtn {
    cursor: pointer;
}

.dateInput {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    
    input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin: 0 auto;
        height: 50px;
    }
}

.clear,
.apply {
    width: 100%;
    padding: 10px 1%;
    border: none;
    margin: 0 auto;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    border-radius: 10px;
}

.clear {
    border: 1px solid #ccc;
}

.apply {
    background-color: rgba(80, 80, 80, 1);
    color: white;
}

.labelText {
    background-color: white;
    margin-left: 10px;
    margin-top: -47px;
    position: absolute;
    overflow: hidden;
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
}
