
.btn_css {
    background-color: #fff;
    color: #000;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
}

.delete_btn {
    background-color: #232323;
    color: white;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
}