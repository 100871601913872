.stimpage {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.detailsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 4rem;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.info label {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9E9E9E;
}

.info span {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.82px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #232323;
}

.logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40rem;
    flex-direction: column;
    gap: 0.7rem;
}