.home {
    padding: 0 20px;
}

.customButton {
    width: 8.9375rem; 
    height: 2.875rem; 
    background-color: #F3F3F3;
    color: black; 
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem; 
    font-weight: 700;
    line-height: 1.375rem; 
    text-align: left;
    text-transform: none;
    
}

.addDiagnosisTitle {
    font-family: 'Montserrat', sans-serif; 
    font-size: 1.125rem; 
    font-weight: 700;
    line-height: 1.625rem; 
    text-align: left;
    color: black; 
}
.gridcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }