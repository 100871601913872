.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
}

.addbtn {
  height: 2.5rem;
  font-family: 'Montserrat';
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 1);
}

.searchInput {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 100%; 
  border: 1px solid rgba(226, 227, 229, 1);
  height: 3.125rem;
}

.searchFilter {
  cursor: pointer;
  height: 2.5rem; 
}

/* Add custom styles for isSpecialButton names */
.selectedButton {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(225, 126, 97, 1);
  font-family: 'Montserrat';
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.22rem;
  text-align: left;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  border: none;
}


.radioGroup {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.radioLabel {
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem; 
  font-weight: 600;
  line-height: 1.07rem;
  color: rgba(35, 35, 35, 1);
  margin-right: 0.625rem;
  text-wrap: nowrap;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dateGroup {
  display: flex;
  align-items: center;
  gap: 1rem;
}

input[type=radio]{
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #BBBBBB;
  background-color: #FFFFFF;
}

input[type="radio"]:checked {
  background-color: #E17E61;
  padding: 2px;
  border: 2px solid #000;
}


.togglebutton{
  padding: 8px, 16px, 8px, 16px;
  border-radius: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  background-color: transparent;
  border: 0px solid transparent;
  margin: 2px;
  color: #727C8F;
  margin: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
  font-weight: 500;
}

.togglebuttonactive{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #e17c64;
  font-weight: 600;
  margin: 5px;
  transition: all .4s ease-in;
}
.btnmain{
  font-family: Montserrat;
font-size: 16px;
font-weight: 500;
line-height: 19.5px;
text-align: left;
padding: 20px;
}