/* OocyteComponent CSS */

.oocyteContainer {
  font-family: Arial, sans-serif;
  text-align: left;
  margin: 1.25rem;
  /* 20px / 16 = 1.25rem */

}

.tabs {
  display: flex;
  justify-content: flex-start;
  gap: 0.625rem;
  margin-bottom: 1.25rem;
  background-color: rgb(252, 252, 252);
}

.tab {
  padding: 0.625rem 3.25rem;
  background-color: rgb(252, 252, 252);
  border-radius: 1.25rem;
  color: black;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  border: #FAFAFA;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #232323;

}


.active {
  background-color: #FFFFFF;
  color: #e17e61;
  border: 1px solid #FFFFFF;
  box-shadow: 2px 2px 8px 0px #0000001A;
  transition: all 0.3s ease;
}

.tabContent {
  font-size: 1rem;
  color: #333;
}



/* table  */
.oocyteContainer {
  width: 100%;
  margin: 1.25rem auto;
  /* 20px / 16 = 1.25rem */
}

.fullWidthTable {
  width: 100%;
}

.fullWidthTable th {
  padding: 0.625rem;
  /* 10px / 16 = 0.625rem */
  text-align: left;
  background-color: #FAFAFA;
  border-radius: 0.25rem;
  /* 4px / 16 = 0.25rem */
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  text-align: left;
  text-decoration-skip-ink: none;

}

.fullWidthTable td {
  padding: 0.625rem;
  /* 10px / 16 = 0.625rem */
}

.fullWidthTable {
  border-radius: 0.25rem;
  /* 4px / 16 = 0.25rem */
}


.PlanInformation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.PlanInformationHeader {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.cancelButton {
  width: 137px;
  height: 46px;
  padding: 12px 40px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #505050;
  opacity: 0.9;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #505050;
  text-transform: none;
}



.saveButton {
  width: 137px;
  height: 46px;
  padding: 12px 40px;
  gap: 10px;
  border-radius: 10px;
  opacity: 0.9;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #f0f0f0;
  background-color: #505050;
  text-transform: none;
}

.thawsubinfo {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  text-decoration-skip-ink: none;

}


.griditem {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  text-decoration-skip-ink: none;

}

.value {
  background-color: #ffece5;
  border-radius: 50%;
  padding: 2px 6px;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}

.centeredContainer {
  display: flex;
  justify-content: center;
  align-items: center;

}
