.container {
    padding: 50px;
    border: 1.12px solid rgba(226, 227, 229, 1);
    box-sizing: border-box;
    border-radius: 13.43px;
}

.headerTextStyle {
    font-family: 'Nunito', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 25.6px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(187, 187, 187, 1);
}

.customTextStyle {
    font-family: 'Nunito', sans-serif;
    font-size: 12.43px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: rgba(35, 35, 35, 1);
}



.containerd {
    position: relative;
    background-color: #fafafa;
    width: 183px;
    height: 70px;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    position: absolute;
    top: -12px;
    background-color: #fdd1d1;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    color: #000;
}

.circle::after {
    content: '';
    position: absolute;
    background: white;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    top: 6px;
    left: 6px;
    z-index: -1;
}

.text {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    letter-spacing: 2px;
    text-transform: uppercase;
}



.containerBOX {
    display: flex;
    gap: 8px;
}

.box {
    width: 20.54px;
    height: 20.54px;
    border: 0.86px solid rgba(232, 232, 232, 1);
    border-radius: 4.28px;
    opacity: 1;
}

.boxCulture {
    width: 20.54px;
    height: 20.54px;
    border-radius: 4.28px;
    opacity: 1;
    border: 0.86px solid rgba(232, 232, 232, 1)
}


.boxDiscard {
    width: 20.54px;
    height: 20.54px;
    border: 0.86px solid rgba(232, 232, 232, 1);
    border-radius: 4.28px;
    opacity: 1;
    background-color: rgba(185, 185, 185, 1);

}

.box:nth-child(1) {
    background: rgba(248, 204, 203, 1);
}

.box:nth-child(2) {
    background: rgba(185, 185, 185, 1);
}

.box:nth-child(3) {
    background: rgba(248, 204, 203, 1);
}