.tabs {
    display: flex;
    gap: 10px;
    background-color: #FCFCFC;
    width: 20%;
    border-radius: 15px;
    min-width: 250px;
    padding: 5px;
  }
  
  .tab {
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 15px;
    width: 50%;

    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
    background-color: transparent;
    color: #f65e39;
  }
  
  .tab .active {
    background-color: rgba(255, 165, 0, 0.1);
    color: #f65e39;
    font-weight: bold;
  }
  
  .tab:not(.active) {
    color: #000;
  }
  
  .tab:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  