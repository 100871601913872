
.md-switching-view-cont .mbsc-segmented {
    max-width: 350px;
    margin: 0 auto;
  }
  
  .md-switching-view-cont .mbsc-calendar-grid-item {
    height: 490px;
    box-sizing: border-box;
  }
  
  .cal-header-picker {
    flex: 1 0 auto;
  }
  
  .cal-header-nav {
    width: 200px;
  }
  
  /* material header order */
  
  .mbsc-material.cal-header-prev {
    order: 1;
  }
  
  .mbsc-material.cal-header-next {
    order: 2;
  }
  
  .mbsc-material.cal-header-nav {
    order: 3;
  }
  
  .mbsc-material .cal-header-picker {
    order: 4;
  }
  
  .mbsc-material .cal-header-today {
    order: 5;
  }
  
  /* windows header order */
  
  .mbsc-windows.cal-header-nav {
    order: 1;
  }
  
  .mbsc-windows.cal-header-prev {
    order: 2;
  }
  
  .mbsc-windows.cal-header-next {
    order: 3;
  }
  
  .mbsc-windows .cal-header-picker {
    order: 4;
  }
  
  .mbsc-windows .cal-header-today {
    order: 5;
  }
  
  .cal-container {
    padding: 0.2rem;
    display: flex;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
  }
  
  .table-parent {
    cursor: pointer;
  }
  
  .cal-container > * {
    max-width: fit-content;
  }
  
  .cal-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .cal-nav > * {
    margin: 0 1rem;
  }
  
  .cal-ftr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .cal-radio {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    gap: 0.5rem;
  }
  
  .cal-res-ava {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
  }

  .cal-tabs-sec {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }

  .cal-event-label  {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .cal-event-label svg{
    height: 0.7rem;
  }


  .mbsc-schedule-time-wrapper,
  .mbsc-schedule-item {
    height: 90px;
  }

/* Increase the height of each row in Mobiscroll Day View */
.mbsc-schedule-row {
    height: 80px !important; /* Adjust the height value as needed */
}

.mbsc-schedule-day .mbsc-schedule-grid-cell {
    height: 80px !important; /* Adjust grid cell height as well */
}

.mbsc-schedule-day .mbsc-schedule-hour {
    height: 80px !important; /* Adjust the hour column height */
}

.mbsc-ios.mbsc-segmented {
    background: #ececec;
    width: 15rem;
}

.cal-nav > * {
    margin: 0;
    width: fit-content;
}

 .mbsc-schedule-event-more {
  height: auto !important;
}

.cal-deps {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.cal-ooo-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.cal-ooo-modal {
    width: fit-content;
    border-radius: 4px;
    background: white;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    border-radius: 1rem;
}

.cal-ooo-heading {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}

.cal-ooo-heading h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.cal-ooo-secinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.cal-ooo-events {
  background-color: #47494a;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8"><line x1="10" y1="0" x2="2" y2="10" stroke="white" stroke-width="2"/></svg>');
}

.cal-event-cancel {
  color: rgb(126, 0, 0);
  background-color: #fefefe;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8"><line x1="10" y1="0" x2="0" y2="1" stroke="red" stroke-width="1"/></svg>');
}

.calFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  height: 3rem;
  gap: 0.5rem;
  padding-right: 3rem;
}