
  .DialogTitle{
      font-size: 20px;
      font-weight: 700;
      line-height: 26.1px;
      text-align: left;
      font-family: 'Montserrat';
}

.DialogSubTitle{
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 400;
    color: #404653;
}

.overlayFadeIn{
    animation: fadeIn 0.6s ease-in-out; 
}


.card {
    animation: fadeIn 0.3s ease-in-out; /* Entry animation */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
  }
  
  .card:hover {
    transform: scale(1.001); /* Scale effect on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px); /* Slight drop */
    }
    to {
      opacity: 1;
      transform: translateY(0); /* Neutral position */
    }
  }