.login {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
}

.banner{
    width: 66.66vw;
    height: 100%;

    background-image: url('../../assets/SVG/LoginBackground.svg'); /* path to your background image */
  background-size: cover; /* make sure the background image covers the entire element */
  background-repeat: no-repeat; /* prevent the background image from repeating */
  background-position: center; /* center the background image */
}



.banner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (min-width: 1800px) {
    .banner img {
        object-fit: cover;
    }
  }

.panel {
    display: flex;
    width: 33.3vw;
    height: fit-content;
    /* // min-height: 100%; */
    flex-direction: column;
    align-items: center;
}

/* .logo {
//    margin-top: 10rem;
} */

.form {
    margin-top: 4rem;
    height: 28rem;
    width: 70%;
}

.bannertext {
    position: relative; 
    bottom: 180px; 
    color: #232323 !important; 
    font-weight: 200 !important;
    font-size: 28px; 
    width: 90%;
    padding-left: 25px;
    box-sizing: border-box;
  }

  .AIPowerCss{
    color: #E17E61;
  }

  @media (max-width: 1000px) {
    .banner {
      display: none;
    }

    .login {
      width: 100%;
    }

    .panel {
      width: 100%;
    }
  }