.container {
    padding: 50px;
    border: 1.12px solid rgba(226, 227, 229, 1);
    box-sizing: border-box;
    border-radius: 13.43px;
  }
  
  .embryoProcedure {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .textField {
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  
  .headerTextStyle {
    font-family: 'Nunito', sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 25.6px;
    color: rgba(187, 187, 187, 1);
  }
  
  .customTextStyle {
    font-family: 'Nunito', sans-serif;
    font-size: 14.43px;
    font-weight: 700;
    line-height: 16.8px;
    color: rgba(35, 35, 35, 1);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 20px;
    }
  
    .embryoProcedure {
      margin-bottom: 20px;
    }
  }
  

  .headerTextTable {
    border: 1px solid #ddd
}
.Embryotext{
    font-family: 'Montserrat', sans-serif;
    font-size: 15.98px;
    font-weight: 700;
    line-height: 16.78px;
    text-align: left;
    text-decoration-skip-ink: none; 
}
