/* CustomTableComponent.css */

.custom_table .custom_table_head {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    text-align: left;
    color: #101828;
    background-color: #FAFAFA;
}

.custom_table .custom_table_body{
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    padding: 0px;
    text-align: left;
    background: #FFFFFF;
    cursor: pointer;
}

.custom_table .custom_table_body:hover{
    background-color: rgb(236, 234, 234);
}

.custom_table .custom_table_body {
    background-color: white; /* Initial background color */
    transition: background-color 0.3s ease; /* Smooth animation */
}
.styles.custom_table_cell{
    padding: 0px !important;
}
.tb_main_text {
    font-family: Nunito;
font-size: 16px;
font-weight: 500;
line-height: 23.2px;
text-align: left;
}