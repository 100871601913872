.main {
    width: 100%;
}

.logo_container img {
    width: 8.375rem;
    height: 2.875rem;
    flex-shrink: 0;
}

.logo_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.main > * {
    box-shadow: 0;
}

@media screen and (max-width: 950px) {
    .MuiDrawer-paper {
      width: 250px; /* Set width for drawer on smaller screens */
    }
  }
    
