.container {
    padding-left: 16px;
    padding-right: 16px;

    button {
        height: 2.875rem;
        width: 14.875rem;       
        margin-right: 10px;
        border: none;
        border-radius: 10px;
    }

    .textareaWrapper {
        textarea::placeholder {
            color: rgba(187, 187, 187, 1);
        }
    }
}

.titleText {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: left;
    color: rgba(35, 35, 35, 1);
}

.textmuted {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    text-align: left;
    color: rgba(64, 70, 83, 1);
}

.floatEnd {
    cursor: pointer;
}

.blueText {
    color: blue;
}


.inputField {
    width: 100%;
    height: 100%;
    padding:1.73% 0.64% 1.73% 3.64%;
    border-radius: 9px;
    border: 1px solid rgba(207, 209, 212, 1);
    outline: none;
    box-shadow: none;
    border-radius: 8px;

    &:focus {
        border-color: rgba(207, 209, 212, 1);
    }

    &::placeholder {
        font-family: 'Nunito';
        font-size: 16px;
        font-weight: 400;
        line-height: 21.82px;
        text-align: left;
        color: rgba(158, 158, 158, 1);
    }
}

.inputField::placeholder {
    background-image: url('../../../assets/SVG/Vector.svg');
    background-size: 22px;
    padding-left: 30px;
    color: transparent;
    background-repeat: no-repeat;

    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 400;
    line-height: 21.82px;
    text-align: left;
    color: rgba(158, 158, 158, 1);

}
.save {
    background-color: #000;
    color: white;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
}

.cancel {
    background-color: rgba(243, 243, 243, 1);
    color: rgba(80, 80, 80, 1);
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
}


.small {
    padding: 5px 10px; 
    font-size: 14px; 
}


.buttonDiv {
    display: flex;
    justify-content: flex-end; 
}

.buttonDiv {
    display: flex;
    justify-content: end;
}

.input-pointer {
    cursor: pointer;
}

.tableCell {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 500;
    line-height: 21.82px;
    text-align: left;
    color: rgba(16, 24, 40, 1);
}

.wrapText {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cancelWrapper {
    margin-right: 8px;
    display: inline-block;
}

.inputWrapper {
    margin-bottom: 20px;

    .fullWidth {
        width: 100%;
        height: 50px;
        padding: 13px 16px;
        border-radius: 10px;
        border: 1px solid #ccc;
        opacity: 1;
    }
}

.textareaWrapper {
    .fullWidth {
        width: 100%;
        height: 99px;
        padding: 13px 16px;
        border-radius: 10px;
        border: 1px solid #ccc;
        opacity: 1;
    }
}

.buttonDivdelink {
    display: flex;
    justify-content: center;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.textspan {
    display: grid;
}

.labelText {
    background-color: white;
    margin-left: 10px;
    margin-top: -11px;
    position: absolute;
    overflow: hidden;
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;


}


.spantext{
font-family: 'Nunito';
font-size: 16px;
font-weight: 400;
line-height: 23.2px;
color: #404653;
text-align: left;
}


/* table css' */

/* Container and title styling */
.container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .titleText {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  .floatEnd {
    cursor: pointer;
  }
  
  /* Input field styling */
  .inputField {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
  }
  
  /* Table styling */
  .tableContainer {
    margin-top: 20px;
    overflow-x: auto; /* Horizontal scroll on small screens */
    max-height: 400px; /* Set your desired height */
    overflow-y: auto; /* Vertical scrolling */
}
  
  .customTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .customTable th, .customTable td {
    padding: 12px;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
  }
  
  .customTable th {
    background-color: #f8f8f8;
    font-weight: 500;
    color: #333;
  }
  
  .customTable tr:hover {
    background-color: #f1f1f1;
  }
  
  .customTable td {
    color: #555;
  }
  
  .noDataRow {
    text-align: center;
    font-style: italic;
    color: #aaa;
  }
  