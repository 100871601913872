.form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.headings {
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.headings span {
    color: #9E9E9E;
}

.fields {
    height: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;

}


.input {
    margin-bottom: '20px'; 
}

.icon {
    margin-bottom: '16px'; 
    margin-right: '12px'; 
}

.login {
    font-size: 1.2rem;
}

.password_fg {
    font-size: 1rem;
}

.action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

@media (min-width: 1000px) {
    .login {
      width: 100%;
    }
  }