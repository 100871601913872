.commonOrder{
    font-family: 'Montserrat';
font-size: 20px;
font-weight: 700;
line-height: 26.1px;
text-align: left;

}
p{
    margin-bottom: 0px;
}
.content{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 26.1px;
    text-align: left; 
}

.content1{
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 700;
  line-height: 26.1px;
  text-align: left; 
}

.truncatedTitle {
    max-width: 150px; /* Adjust the width as per your design */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    cursor: pointer; /* Indicates hoverable text */
  }
  


  .truncatedTitle2 {
   
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    cursor: pointer; /* Indicates hoverable text */
    width: 12rem;
  }
  
  .truncatedTitle3 {
    max-width: 150px; /* Adjust the width as per your design */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    cursor: pointer; /* Indicates hoverable text */
  }
  
  
  .fade_In {
    opacity: 0;
    animation: fadeIn 0.3s ease-in-out forwards;
  }
  
/* Fade-Out Animation */
.fade_out {
  opacity: 1;
  animation: fadeOut 0.6s ease-in-out forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}


.fadein {
  opacity: 0;
  transform: translateY(20px); /* Optional: Slide effect */
  animation: fadeInAnimation 0.5s forwards;
}

@keyframes fadeInAnimation {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

  .truncatedTitle1 {
    max-width: 160px; /* Adjust the width as per your design */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    cursor: pointer; /* Indicates hoverable text */
  }

  @media (min-width: 1380px) and (max-width: 1600px) {
    .truncatedTitle1 {
        max-width: 150px;
    }
    .truncatedTitle3 {
      max-width: 150px;
  }
    .content{
      font-size: 14px;
    
  }
}


@media (min-width: 950px) and (max-width: 1380px) {
  .truncatedTitle1 {
      max-width: 140px;
  }
  .content{
    font-size: 12px;
  
}
}

 
@media (min-width: 1600px) and (max-width: 1880px) {
  .truncatedTitle3 {
    max-width: 170px;
}
}
  @media (min-width: 1880px) {
    .truncatedTitle1 {
        max-width: 220px; /* Width above 1880px */
    }
    .truncatedTitle3 {
      max-width: 220px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .truncatedTitle2 {
    max-width: 125px;
  }
}

@media (max-width: 1700px) {
  .truncatedTitle2 {
    width: 8.2rem;
  }
}


  .prevInvest{
    font-family: 'Nunito';
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #5A8AEA;
    text-decoration: none;
    cursor: pointer;
  }


  .fade {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }
  
  .fade-active {
    opacity: 1;
  }